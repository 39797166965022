import React, {Component} from "react";
import {Toast} from "primereact/toast";
import {ProgressSpinner} from "primereact/progressspinner";
import {Card} from "primereact/card";
import GoogleMapReact from 'google-map-react';
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {DateService} from "../../service/util/DateService";


const MapMark = ({text, id}) => <div className="map-mark" id={"name_" + id} name={"name_" + id} data-tooltip={text}></div>;
export default class Maps extends Component

{

    constructor(props) {
        super(props);
        this.props = {
            dataSet: []
        }

        this.state = {
            location:[],
            GOOGLE_MAPS_KEY: "AIzaSyDwvpofRuIk2_azlQh3gV3FNvwTjV8EyjE",
            center: {
                lat: 19.434054,
                lng: -99.133106
            },
            zoom: 13,
            chargedYet: true,
            datesFilter: [],
            errors: {},
            processSuccess: true,
        }

        this.searchReportMaps = this.searchReportMaps.bind(this);

        this.worxflowServices = new WorxflowServices();
        this.dateService = new DateService();
    }
    componentDidMount() {
        const myProp = this.props.dataSet;
        const location = myProp;
        const pos = {}
        if (myProp.length > 0) {
            pos.lat = location[0].latitude;
            pos.lng = location[0].longitude;
        }
        this.setState({location, center:pos});
        this.searchReportMaps();
    }


    searchReportMaps = () => {
        const params = {
            date_ini: this.props.dataSet.dataTableSelectValue.date,
            date_end: this.props.dataSet.dataTableSelectValue.date,
            time_start: this.props.dataSet.dataTableSelectValue.work_started,
            time_end: this.props.dataSet.dataTableSelectValue.work_ended,
            username: this.props.dataSet.dataTableSelectValue.email
        }
        this.worxflowServices.getLocation(params).then(resp => {
            const location = resp.data;
            const pos = {}
            if (location.length > 0) {
                pos.lat = location[0].latitude;
                pos.lng = location[0].longitude;
            }
            this.setState({location, center:pos});
            this.toast.show({severity:'success', summary: 'Map Success'});
            console.log(location);
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }




    render() {
        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                            <div className="col-12">
                                <div style={{height: '70vh', width: '100%'}}>
                                    {(this.state.location.length > 0) &&
                                        <GoogleMapReact
                                            bootstrapURLKeys={{key: this.state.GOOGLE_MAPS_KEY}}
                                            defaultCenter={this.state.center}
                                            defaultZoom={this.state.zoom}
                                        >
                                            {this.state.location.map((item, index) => {
                                                return (
                                                    <MapMark
                                                        lat={item.latitude}
                                                        lng={item.longitude}
                                                        text={item.email}
                                                    />
                                                );
                                            })}
                                        </GoogleMapReact>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
