import React, {Component} from "react";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {isAmount, isEmpty, rounded, substringDate} from "../utils/commonFunctions";
import {Calendar} from "primereact/calendar";
import {DateService} from "../../service/util/DateService";
import {AutoComplete} from "primereact/autocomplete";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {InputSwitch} from "primereact/inputswitch";
import {Checkbox} from "primereact/checkbox";
import {SelectButton} from "primereact/selectbutton";
import {InputNumber} from "primereact/inputnumber";
import Maps from "./Maps";





class Report extends Component {

    hours = [
        {hr: '00', hr1: '00', hr2: '00'}, {hr: '01', hr1: '01', hr2: '01'}, {hr: '02', hr1: '02', hr2: '02'},
        {hr: '03', hr1: '03', hr2: '03'}, {hr: '04', hr1: '04', hr2: '04'}, {hr: '05', hr1: '05', hr2: '05'},
        {hr: '06', hr1: '06', hr2: '06'}, {hr: '07', hr1: '07', hr2: '07'}, {hr: '08', hr1: '08', hr2: '08'},
        {hr: '09', hr1: '09', hr2: '09'}, {hr: '10', hr1: '10', hr2: '10'}, {hr: '11', hr1: '11', hr2: '11'},
        {hr: '12', hr1: '12', hr2: '12'}, {hr: '13', hr1: '13', hr2: '13'}, {hr: '14', hr1: '14', hr2: '14'},
        {hr: '15', hr1: '15', hr2: '15'}, {hr: '16', hr1: '16', hr2: '16'}, {hr: '17', hr1: '17', hr2: '17'},
        {hr: '18', hr1: '18', hr2: '18'}, {hr: '19', hr1: '19', hr2: '19'}, {hr: '20', hr1: '20', hr2: '20'},
        {hr: '21', hr1: '21', hr2: '21'}, {hr: '22', hr1: '22', hr2: '22'}, {hr: '23', hr1: '23', hr2: '23'}
    ];

    minutes = [
        {min: '00'}, {min: '01'}, {min: '02'}, {min: '03'}, {min: '04'}, {min: '05'}, {min: '06'}, {min: '07'}, {min: '08'}, {min: '09'},
        {min: '10'}, {min: '11'}, {min: '12'}, {min: '13'}, {min: '14'}, {min: '15'}, {min: '16'}, {min: '17'}, {min: '18'}, {min: '19'},
        {min: '20'}, {min: '21'}, {min: '22'}, {min: '23'}, {min: '24'}, {min: '25'}, {min: '26'}, {min: '27'}, {min: '28'}, {min: '29'},
        {min: '30'}, {min: '31'}, {min: '32'}, {min: '33'}, {min: '34'}, {min: '35'}, {min: '36'}, {min: '37'}, {min: '38'}, {min: '39'},
        {min: '40'}, {min: '41'}, {min: '42'}, {min: '43'}, {min: '44'}, {min: '45'}, {min: '46'}, {min: '47'}, {min: '48'}, {min: '49'},
        {min: '50'}, {min: '51'}, {min: '52'}, {min: '53'}, {min: '54'}, {min: '55'}, {min: '56'}, {min: '57'}, {min: '58'}, {min: '59'}
    ];

    serviceTypes = [
        {label: 'Service', value: 'SE'},
        {label: 'Construction', value: 'CT'},
        {label: 'PM', value: 'PM'},
        {label: 'Dispatch', value: 'DP'}
    ];

    registers = [
        { reg: 'All Time sheets Data Source', code: null},
        { reg: 'Data from App', code: 'A'},
        { reg: 'Data from Technicians', code: 'W'}
    ];

    billed = [
        { bl: 'All Time sheets', boolean: null},
        { bl: 'Billed', boolean: true},
        { bl: 'Not Billed', boolean: false}
    ];

    dataSet =
        {
            date_ini: "2024-05-01",
            date_end: "2024-05-31",
            time_start: "19:00:02",
            time_end: "19:59:02",
            username: "olivia@worxflow.net"
        }




    constructor(props) {

        super(props);
        this.state = {
            opcionMenuSelected: 'mapTimesheetMn',

            customers1: [],
            customers: [],
            first1: 0,
            rows1: 10,
            currentPage: 1,
            workStarted1: [],
            workStarted2: [],
            workEnded1: [],
            workEnded2: [],
            numberWo: null,
            chargedYet: false,
            isSwitched: false,
            datesFilter: [],
            technicians: [],
            technn: [],
            filteredWoO: [],
            filteredTechnn:[],
            filteredCustomer:[],
            techWorkOrders: [],
            selectedRegister: this.registers[0],
            selectedBilled: this.billed[0],
            auditTime: [],
            filteredTechnicians: null,
            technicianForm: '',
            selectedTechnician: '',
            emailSelect: '',
            teams: [],
            filteredTeams: null,
            teamForm: '',
            teamSelect: '',
            weeks: [],
            filteredWeeks: null,
            weekForm: null,
            weekSelect: undefined,
            workOrder: [],
            daysProcessed: [],
            rawReport: [],
            report: {
                id: null,
                profile: "",
                profile_desc: "",
                work_order: "",
                customer_data: "",
                date: "",
                after_hours: "",
                per_diem: 0,
                pto: 0,
                work_started: "",
                work_ended: "",
                technician: "",
                selected_hour_1: "",
                selected_hour_2: "",
                selected_minute_1: "",
                selected_minute_2: "",
                total_hours: "",
                total_hours_f: 0,
                work_st: "",
                work_ot: "",
                work_pto: "",
                work_diff: "",
                d_created: "",
                reason_id: '',
            },
            dataTableSelectValue: this.emptyTimesheet,
            recipientActionType: null,
            serviceTypeForm: '',
            errors: {},
            processSuccess: true,
            timesheetDialog: false,
            mapsDialog: false,
            auditTimesheetDialog: false,
            value: true,
            displayConfirm: false,
            reviewDialogConfirm: false,
        }
        this.options = [
            {name: 'No', value: "N"},
            {name: 'Yes', value: "Y"}
        ];
        this.csvExporter1 = null;
        this.csvExporter2 = null;


        this.showBox = this.showBox.bind(this);
        this.onCustomPage = this.onCustomPage.bind(this);
        this.onPageInputKeyDown = this.onPageInputKeyDown.bind(this);
        this.onPageInputChange = this.onPageInputChange.bind(this);
        this.onRegisterChange = this.onRegisterChange.bind(this);
        this.onBilledChange = this.onBilledChange.bind(this);
        this.justifyTemplate = this.justifyTemplate.bind(this);
        this.searchReport = this.searchReport.bind(this);
        this.searchMaps = this.searchMaps.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlerReport = this.changeHandlerReport.bind(this);
        this.changeHandlerWorkOrder = this.changeHandlerWorkOrder.bind(this);
        this.changeHandlerTimesheet = this.changeHandlerTimesheet.bind(this);
        this.onHourChange = this.onHourChange.bind(this);
        this.onMinutesChange = this.onMinutesChange.bind(this);
        this.onHourChange2 = this.onHourChange2.bind(this);
        this.onMinutesChange2 = this.onMinutesChange2.bind(this);
        this.headerTemplate = this.headerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.templateTechnician = this.templateTechnician.bind(this);
        this.searchTechnicians = this.searchTechnicians.bind(this);
        /*this.templateTechnn = this.templateTechnn.bind(this);
        this.searchTechnn = this.searchTechnn.bind(this);*/
        this.templateTeam = this.templateTeam.bind(this);
        this.searchTeams = this.searchTeams.bind(this);
        this.templateWeek = this.templateWeek.bind(this);
        this.searchWeeks = this.searchWeeks.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.actionBodyTemplateStatus_edit = this.actionBodyTemplateStatus_edit.bind(this);
        this.editTimeSheet = this.editTimeSheet.bind(this);
        this.showMap = this.showMap.bind(this);
        this.updateTimeSheet = this.updateTimeSheet.bind(this);
        this.updateDataTable = this.updateDataTable.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.hideMap = this.hideMap.bind(this);
        this.auditTimesheets = this.auditTimesheets.bind(this);
        this.payTimesheets = this.payTimesheets.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.reviewedTimesheet = this.reviewedTimesheet.bind(this);
        this.actionBodyTemplateOrigin = this.actionBodyTemplateOrigin.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.formatDataListAfter = this.formatDataListAfter.bind(this);
        this.formatDataListBefore = this.formatDataListBefore.bind(this);
        this.onChangeSwitch = this.onChangeSwitch.bind(this);
        this.templateWorkOrder = this.templateWorkOrder.bind(this);
        this.searchWoO = this.searchWoO.bind(this);
        this.searchCustomers = this.searchCustomers.bind(this);
        this.loadCatalogCustomers = this.loadCatalogCustomers.bind(this);
        this.templateCustomers = this.templateCustomers.bind(this);
        this.showData = this.showData.bind(this);
        this.searchTechnicianWorkOrders = this.searchTechnicianWorkOrders.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.minDate = new Date();
        this.maxDate = new Date();
        this.dateService = new DateService();


    }
    emptyTimesheet = {
        id: 0,
        week_payroll: "",
        control_id: 0,
        work_order: "",
        customer_id: 0,
        customer: "",
        date: "",
        after_hours: "",
        per_diem: null,
        pto: null,
        work_started: "",
        work_ended: "",
        total_hours: null,
        total_hours_f: 0,
        work_st: null,
        work_ot: null,
        work_pto: null,
        work_diff: null,
        is_legacy: true,
        status: "",
        d_created: "",
        first_name: "",
        last_name: "",
        email: "",
        address: null,
        st_hours_f: 0,
        ot_hours_f: null,
        differential_hours_f:
            null,
        qty_hours_fe_f: null,
        difference_timesheet_f: 0,
        type: "",
        notes: ""
    };

    showBox = data => () => {
        this.setState({opcionMenuSelected: data});
    }

    componentDidMount() {
        this.worxflowServices.searchReportTimesheet().then(data =>
            this.setState({customers1: data}));
        const technicians = JSON.parse(localStorage.getItem('technicians'));
        if (technicians == null) {
            this.loadTechnicians();
        } else {
            this.setState({ technicians: technicians, chargedYet: true });
        }
        const teams = JSON.parse(localStorage.getItem('teams'));
        if (teams == null) {
            this.loadTeams();
        } else {
            this.setState({ teams: teams });
        }
    }



    searchReport = () => {
        const from = this.state.valueFrom
        console.log(from)
        const paramsValidate = {
            date_start: this.dateService.dateToStringFront3(this.state.datesFilter[0]),
            date_end: this.dateService.dateToStringFront3(this.state.datesFilter[1]),
            workOrder: this.state.numberWo
        };
        this.setState({processSuccess: true});
        const errors = this.validate(paramsValidate);
        if (Object.keys(errors).length ===  0) {
            this.setState({chargedYet: false});
            const params = {
                date_start: paramsValidate.date_start,
                date_end: paramsValidate.date_end,
                email: (this.state.emailSelect !== '') ? this.state.emailSelect.value : null,
                team_id: (this.state.teamSelect !== '') ? this.state.teamSelect.value : null,
                type: this.state.serviceTypeForm === undefined ? '' : this.state.serviceTypeForm,
                work_order: this.state.numberWo ? this.state.numberWo : '',
                source: this.state.selectedRegister.code,
                is_payment: this.state.selectedBilled.boolean,
                control_id: null,
                is_week: false
            };
            this.worxflowServices.searchReportTimesheetV3(params).then(resp => {
                if (resp.status_code === 200) {
                    const rawReport = resp.data.datos;
                    this.setState({rawReport});
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                this.setState({chargedYet: true});
                this.toast.show({severity: 'error', summary: 'Error', detail: error.response?.data?.message});
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.setState({errors, processSuccess: false});
        }
        this.searchReportList();
    }

    searchReportList(){

        const paramsValidate = {
            date_start: this.dateService.dateToStringFront3(this.state.datesFilter[0]),
            date_end: this.dateService.dateToStringFront3(this.state.datesFilter[1]),
        };
        this.setState({processSuccess: true});
        const errors = this.validate(paramsValidate);
        if (Object.keys(errors).length ===  0) {
            this.setState({chargedYet: false});
            const params = {
                date_start: paramsValidate.date_start,
                date_end: paramsValidate.date_end,
                email: (this.state.emailSelect !== '') ? this.state.emailSelect.value : null,
                team_id: (this.state.teamSelect !== '') ? this.state.teamSelect.value : null,
                type: this.state.serviceTypeForm === undefined ? '' : this.state.serviceTypeForm,
                work_order: this.state.numberWo ? this.state.numberWo : '',
                source: this.state.selectedRegister.code,
                is_payment: this.state.selectedBilled.boolean,
                control_id: null,
                is_week: true
            };
            this.worxflowServices.searchReportTimesheetV3(params).then(resp => {
                if (resp.status_code === 200) {
                    const rawReport2 = [];
                    resp.data.forEach(element => {
                        let dataHeader = "Week:" + element.id +
                            " Start: " + element.start_date +
                            " End: " + element.end_date +
                            " Technician: " + element.username;
                        if (element.processed_data === null || element.processed_data.length === 0) {
                            element.header = dataHeader;
                            element.footerTotalHoursF = rounded(element.total_hours_f);
                            element.footerTotalPerDiem = element.total_per_diem;
                            element.footerTotalPto = element.total_pto;
                            element.footerTotalWorkSt = element.total_work_st;
                            element.footerTotalWorkOt = element.total_work_ot;
                            element.footerTotalWorkPto = element.total_work_pto;
                            element.footerTotalWorkDiff = element.total_work_diff;
                            element.fullName = "";
                            rawReport2.push(element);
                        }
                        element.processed_data.forEach(e => {
                            e.header = dataHeader;
                            e.footerTotalHoursF = parseFloat(element.total_hours_f.toFixed(2));
                            e.footerTotalWorkSt = parseFloat(element.st_hours_f.toFixed(2));
                            e.footerTotalWorkOt = parseFloat(element.ot_hours_f.toFixed(2));
                            e.footerTotalPerDiem = element.total_per_diem;
                            e.footerTotalPto = element.total_pto;
                            e.footerTotalWorkPto = element.total_work_pto;
                            e.footerTotalWorkDiff = element.total_work_diff;
                            e.total_hours_f = rounded(e.total_hours_f);
                            e.d_created = substringDate(e.d_created, 10);
                            e.fullName = e.first_name + " " + e.last_name;
                            rawReport2.push(e);
                        });
                    });
                    this.setState({rawReport2, report: resp.data});
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                this.setState({chargedYet: true});
                this.toast.show({severity: 'error', summary: 'Error', detail: error.response?.data?.message});
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.setState({errors, processSuccess: false});
        }
    }

    searchMaps = () => {
        const paramsValidate = {
            date_start: this.dateService.dateToStringFront3(this.state.datesFilter[0]),
            date_end: this.dateService.dateToStringFront3(this.state.datesFilter[1]),
        };
        this.setState({processSuccess: true});
        const params = {
            "date_ini": paramsValidate.date_start,
            "date_end": paramsValidate.date_end,
            "username": (this.state.emailSelect !== '') ? this.state.emailSelect.value : null,
        };
        this.worxflowServices.getListUbication(params).then(resp => {
            const location = resp.data;
            const pos = {}
            if (location.length > 0) {
                pos.lat = location[0].latitude;
                pos.lng = location[0].longitude;
            }
            this.setState({location, center:pos});
            this.toast.show({severity:'success', summary: 'Success'});
            console.log(location);
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }


    onPageInputChange (event) {
        this.setState({ currentPage: event.target.value });
    }

    onCustomPage (event) {
        this.setState({
            first1: event.first,
            rows1: event.rows
        });
    }

    onPageInputKeyDown (event, options) {
        if (event.key === 'Enter') {
            const page = parseInt(this.state.currentPage);
            if (page < 0 || page > options.totalPages) {
                this.setState({ pageInputTooltip: `Value must be between 1 and ${options.totalPages}.`})
            }
            else {
                const first = this.state.currentPage ? options.rows * (page - 1) : 0;

                this.setState({ first1: first, pageInputTooltip: 'Press \'Enter\' key to go to this page.' });
            }
        }
    }



    onRegisterChange(e) {
        this.setState({selectedRegister: e.value});
    }

    onBilledChange(e) {
        this.setState({selectedBilled: e.value});
    }



    searchTeams(event) {
        setTimeout(() => {
            let filteredTeams;
            if (!event.query.trim().length) {
                filteredTeams = [...this.state.teams];
            } else {
                filteredTeams = this.state.teams.filter((team) => {
                    return team.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredTeams });
        }, 250);
    }

    searchTechnicians(event) {
        setTimeout(() => {
            let filteredTechnicians;
            if (!event.query.trim().length) {
                filteredTechnicians = [...this.state.technicians];
            } else {
                filteredTechnicians = this.state.technicians.filter((technician) => {
                    return technician.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredTechnicians });
        }, 250);
    }

    searchCustomers(event) {
        this.setState({
            report: {
                ...this.state.report,

            }, workOrderSelect: null})

        if (this.state.workOrderSelect !== null){
            this.setState({
                report: {
                    ...this.state.report,
                    customer_data: null
                }, })
        }

        if (this.state.customer_data !== null){
            this.setState({
                report: {
                    ...this.state.report,
                }, workOrderSelect: null })
        }

        setTimeout(() => {
            let filteredCustomer;
            if (!event.query.trim().length) {
                filteredCustomer = [...this.state.customers];
            } else {
                filteredCustomer = this.state.customers.filter((customer) => {
                    return customer && typeof customer.label === 'string' && customer.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCustomer });
        }, 250);
    }

    /*searchTechnn(event) {
        setTimeout(() => {
            let filteredTechnn;
            if (!event.query.trim().length) {
                filteredTechnn = [...this.state.technn];
            } else {
                filteredTechnn = this.state.technn.filter((technician) => {
                    return technician.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredTechnn });
        }, 250);
    }*/

    getListProcessedWeeks() {
        this.worxflowServices.getListProcessedWeeks().then(resp => {
            let daysProcessed = [];
            resp.forEach(week => {
                let yyMmDd = week.started.split('-');
                let started = new Date(yyMmDd[0], yyMmDd[1]-1, yyMmDd[2]);
                yyMmDd = week.ended.split('-');
                let ended = new Date(yyMmDd[0], yyMmDd[1]-1, yyMmDd[2]);
                while(started <= ended){
                    let newDate = new Date(started.getFullYear(), started.getMonth(), started.getDate());
                    daysProcessed.push(newDate);
                    started.setDate(started.getDate() + 1);
                }
            });
            this.setState({daysProcessed: daysProcessed});
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }

    loadTechnicians(){
        this.worxflowServices.getTechniciansList().then(resp => {
            let technicians = [];
            const map = new Map();
            resp.data.forEach(element => {
                if(!map.has(element.email)) {
                    if(!isEmpty(element.email)){
                        map.set(element.email, true);
                        technicians.push({
                            value: element.email,
                            label: element.first_name + ' ' + element.last_name + " - " + element.email
                        });
                    }
                }
            });
            this.setState({
                technicians: technicians,
                chargedYet: true
            });
            localStorage.setItem('technicians', JSON.stringify(technicians));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }

    loadCatalogCustomers() {
        this.worxflowServices.getCustomerList().then(resp => {
            let customerList = [];
                if (resp.status === "OK") {
                    resp.data.forEach(d => {
                        customerList.push({
                            label: d.name,
                            value: d.id
                        });
                    })
                    this.setState({
                        customers: customerList,
                        chargedYet: true});
                }
            }).catch(error => {
                console.error("Error on service: ", error);
            });
    }

    /*loadTechnn(){
        this.worxflowServices.getTechniciansList().then(resp => {
            let technicians = [];
            const map = new Map();
            resp.data.forEach(element => {
                if(!map.has(element.email)) {
                    if(!isEmpty(element.email)){
                        map.set(element.email, true);
                        technicians.push({
                            first_name: element.first_name,
                            last_name: element.last_name,
                            email: element.email,
                            label: element.first_name + ' ' + element.last_name + " - " + element.email
                        });
                    }
                }
            });

            this.setState({
                technn: technicians,
                chargedYet: true
            });
            localStorage.setItem('technicians', JSON.stringify(technicians));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }*/


    loadTeams(){
        this.worxflowServices.getTeamsList().then(resp => {
            let teams = [];
            const map = new Map();
            resp.forEach(element => {
                if(!map.has(element.id)) {
                    map.set(element.id, true);
                    teams.push({value: element.id, label: element.name});
                }
            });
            this.setState({teams: teams});
            localStorage.setItem('teams', JSON.stringify(teams));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }
    loadWeeks() {
        this.worxflowServices.getListProcessedWeeks().then(resp => {
            let weeks = [];
            const map = new Map();
            resp.forEach(element => {
                if(!map.has(element.id)) {
                    map.set(element.id, true);
                    weeks.push({value: element.id, label: element.week});
                }
            });
            weeks = weeks.sort((a, b) => a - b);
            this.setState({weeks: resp});
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }

    calculateMinMaxDateCalendar() {
        let today = new Date();

        switch (today.getDay()) {
            case 0:
                this.minDate.setDate(today.getDate() - 3);
                this.maxDate.setDate(today.getDate() + 4);
                break;
            case 1:
                this.minDate.setDate(today.getDate() - 4);
                this.maxDate.setDate(today.getDate() + 3);
                break;
            case 2:
                this.minDate.setDate(today.getDate() - 5);
                this.maxDate.setDate(today.getDate() + 2);
                break;
            case 3:
                this.minDate.setDate(today.getDate() - 6);
                this.maxDate.setDate(today.getDate() + 1);
                break;
            case 4:
                this.minDate.setDate(today.getDate() - 0);
                this.maxDate.setDate(today.getDate() + 7);
                break;
            case 5:
                this.minDate.setDate(today.getDate() - 1);
                this.maxDate.setDate(today.getDate() + 6);
                break;
            case 6:
                this.minDate.setDate(today.getDate() - 2);
                this.maxDate.setDate(today.getDate() + 5);
                break;
            default:
                break;
        }


        this.minDate.setDate(today.getDate() - 365);
        this.maxDate.setDate(today.getDate() + 365);
    }

    showData(row) {
        this.calculateMinMaxDateCalendar();
        const techniciann = {
            email: row.email,
            first_name: row.first_name,
            last_name: row.last_name,
            label: row.first_name + ' ' + row.last_name + " - " + row.email
        }
        const workOrderr = row.work_order
        const customerr = row.customer
        const datee = new Date(row.date)
        const afterHourss = row.after_hours
        const perDiemm = row.per_diem
        const ptoo = row.pto
        const timeHour1 = row.work_started[0] + row.work_started[1]
        const timeHour2 = row.work_ended[0] + row.work_ended[1]
        const timeMinutes1 = row.work_started[3] + row.work_started[4]
        const timeMinutes2 = row.work_ended[3] + row.work_ended[4]

        this.setState({customerr, datee, afterHourss, perDiemm, ptoo, timeHour1, timeHour2, timeMinutes1, timeMinutes2,
            report: {
                ...this.state.report,
                technician: techniciann,
                work_order: workOrderr,
                customer: customerr,
                date: datee,
                after_hours: afterHourss,
                per_diem: perDiemm,
                pto: ptoo,
                work_started: "",
                work_ended: "",
            },
            selectedTechnician: techniciann,
            workStarted1: timeHour1,
            workStarted2: timeMinutes1,
            workEnded1: timeHour2,
            workEnded2: timeMinutes2
        })
        this.searchTechnicianWorkOrders(row);
    }


    searchTechnicianWorkOrders(row) {
        const param = {
            username: row.email
        };
        this.worxflowServices.getTechnicianWorkOrders(param).then(resp => {

            if (resp.status === "OK") {
                const workOrder = resp.data
                this.setState({workOrder})
            }

        }).catch(error => {
            console.error("Error on service: ", error);
        });
    }





    onHourChange= event => {
        this.setState({
            workStarted1: event.value
        });
        if (event.value.hr !== undefined) {
         const work_startedd = event.value.hr + ":" + this.state.workStarted2 + ":00"
         if (event.value.hr !== this.state.workStarted1.hr) {
            this.setState({
                dataTableSelectValue: {
                    ...this.state.dataTableSelectValue,
                    work_started: work_startedd
                },
                workStarted1: event.value.hr
            })
         }
        } else {
            const work_starteddd = event.value + ":" + this.state.workStarted2 + ":00"
            if (event.value !== this.state.workStarted1) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        work_started: work_starteddd
                    },
                    workStarted1: event.value
                })
            }
        }

    }

    onMinutesChange= event => {
        this.setState({
            workStarted2: event.value
        });
        if (event.value.min !== undefined) {
            const work_startedd2 = this.state.workStarted1 + ":" + event.value.min + ":00"
            if (event.value.min !== this.state.workStarted2) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        work_started: work_startedd2
                    },
                    workStarted2: event.value.min
                })
            }
        } else {
            const work_starteddd2 = this.state.workStarted1 + ":" + event.value + ":00"
            if (event.value !== this.state.workStarted2) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        work_started: work_starteddd2
                    },
                    workStarted2: event.value
                })
            }
        }
    }

    onHourChange2= event => {
        this.setState({
            workEnded1: event.value
        });
        if (event.value.hr !== undefined) {
            const work_endedd = event.value.hr + ":" + this.state.workEnded2 + ":00"
            if (event.value.hr !== this.state.workEnded1.hr) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        work_ended: work_endedd
                    },
                    workEnded1: event.value.hr
                })
            }
        } else {
            const work_endeddd = event.value + ":" + this.state.workEnded2 + ":00"
            if (event.value !== this.state.workEnded1) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        work_ended: work_endeddd
                    },
                    workEnded1: event.value
                })
            }
        }
    }




    onMinutesChange2= event => {
        this.setState({
            workEnded2: event.value
        });
        if (event.value.min !== undefined) {
            const work_endedd2 = this.state.workEnded1 + ":" + event.value.min + ":00"
            if (event.value.min !== this.state.workEnded2) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        work_ended: work_endedd2
                    },
                    workEnded2: event.value.min
                })
            }
        } else {
            const work_endeddd2 = this.state.workEnded1 + ":" + event.value + ":00"
            if (event.value !== this.state.workEnded2) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        work_ended: work_endeddd2
                    },
                    workEnded2: event.value
                })
            }
        }
    }

    changeHandlerReport = event => {
        const {errors, report} = this.state;
        if(event.target.name === 'reason_id') {
            errors['work_order'] = undefined;
        }

        if(event.target.name === 'selectedTechnician'){
            if (event.target.value.label !== this.state.report.technician.label) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        email: event.target.value.email,
                        first_name: event.target.value.first_name,
                        last_name: event.target.value.last_name
                    }
                });
            }
            this.setState({
                selectedTechnician: event.target.value,
                emailSelect: event.target.value
            });
        }
        if(event.target.name === 'customer'){
            if (event.target.value.label !== this.state.report.customer) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        customer: event.target.value.label,
                        work_order: ""
                    },
                    report: {
                        ...this.state.report,
                        work_order: ""
                    }
                });
            }
        }
        if(event.target.name === 'date'){
            if (event.target.value !== this.state.report.date) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        date: event.target.value
                    }
                });
            }
        }
        if(event.target.name === 'after_hours'){
            if (event.target.value !== this.state.report.after_hours) {
                this.setState({
                    dataTableSelectValue: {
                        ...this.state.dataTableSelectValue,
                        after_hours: event.target.value
                    }
                });
            }
        }
        else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
        if (event.target !== undefined && event.target.name !== undefined) {
            if (errors[event.target.name] !== undefined) {
                errors[event.target.name] = undefined;
            }
            report[event.target.name] = event.target.value;
        } else {
            if (errors[event.originalEvent.target.name] !== undefined) {
                errors[event.originalEvent.target.name] = undefined;
            }
            report[event.originalEvent.target.name] = event.originalEvent.target.value;
        }
        this.setState({report, errors});
    }


    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({
            [event.target.name]: event.target.value,
            errors
        });
        if(event.target.name === 'technicianForm'){
            this.setState({
                technicianForm: event.target.value,
                emailSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }

        if(event.target.name === 'teamForm'){
            this.setState({
                teamForm: event.target.value,
                teamSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
        if(event.target.name === 'weekForm'){
            this.setState({
                weekForm: event.target.value,
                weekSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
        if(event.target.name === 'numberWo'){
            if (event.target.value.id !== undefined) {
                this.setState({numberWo: event.target.value.name})
            } else {
                this.setState({numberWo: event.target.value})
            }
        }
    }

    changeHandlerWorkOrder = event => {
        if (event.value.name !== undefined) {
            const select_customer = event.value.partner_id
            const customer_id = event.value.partner_id[0]
            const customer_name = event.value.partner_id[1]
            const customer_d = {
                id: customer_id,
                name: customer_name,
                label: customer_name
            }
            console.log(customer_d)
            this.setState({
                report: {
                    ...this.state.report,
                    customer: customer_d.label,
                    customer_id: customer_d.id,
                    work_order: event.value.name
                },
                dataTableSelectValue: {
                    ...this.state.dataTableSelectValue,
                    work_order: event.value.name,
                    customer: customer_d.label
                }
            })
            this.setState({customer_d, select_customer})
        } else {
            this.setState({
                report: {
                    ...this.state.report,
                    work_order: event.value
                },
                dataTableSelectValue: {
                    ...this.state.dataTableSelectValue,
                    work_order: event.value
                }
            })
        }

        console.info(this.state.report.customer_data)
    }


    validate = (data) => {
        let errors = {};
        if (data.date_start === null && data.date_end === null && data.workOrder === null) {
            errors.datesFilter = 'Select a date range';
        }

        if (data.workOrder !== '') {
            delete errors.date_search
        }

        return errors;
    };
    headerTemplate(data) {
        return (
            <span style={{backgroundColor: "#89cfff"}} key={data.id + '_headerLabel'} className="header_timesheet_management">
                {data.header}
            </span>
        );
    }
    footerTemplate(data, index) {
        return (
            <React.Fragment>
                <td key={data.id + '_footerLabel'} colSpan={5} style={{textAlign: 'right', width: 1115, display:'block', backgroundColor:'#89cfff', fontWeight: 'bold'}}>Totals</td>
                {/*<td style={{textAlign: 'center', width: 80, display:'block', backgroundColor:'#89cfff'}}>{data.footerTotalPerDiem}</td>
                <td style={{textAlign: 'center', width: 80, display:'block', backgroundColor:'#89cfff'}}>{data.footerTotalPto}</td>
                <td style={{width: 120, backgroundColor:'#89cfff'}}/>
                <td style={{width: 100, backgroundColor:'#89cfff'}}/>*/}
                <td style={{textAlign: 'center', width: 100, display:'block', backgroundColor:'#89cfff', fontWeight: 'bold'}}>{data.footerTotalHoursF}</td>
                <td style={{textAlign: 'center', width: 100, display:'block', backgroundColor:'#89cfff', fontWeight: 'bold'}}>{data.footerTotalWorkSt}</td>
                <td style={{textAlign: 'center', width: 100, display:'block', backgroundColor:'#89cfff', fontWeight: 'bold'}}>{data.footerTotalWorkOt}</td>
                <td style={{textAlign: 'center', width: 100, display:'block', backgroundColor:'#89cfff', fontWeight: 'bold'}}>{data.footerTotalWorkPto}</td>
                <td style={{textAlign: 'center', width: 200, display:'block', backgroundColor:'#89cfff', fontWeight: 'bold'}}></td>
                <td style={{width: 100, backgroundColor:'#89cfff'}}/>
                <td style={{width: 160, backgroundColor:'#89cfff'}}/>
                <td style={{width: 100, backgroundColor:'#89cfff'}}/>
                <td style={{width: 100, backgroundColor:'#89cfff'}}/>
                <td style={{width: 300, backgroundColor:'#89cfff'}}/>
                <td style={{minWidth: '4rem', width: 40, backgroundColor:'#eaf7fe'}}/>
            </React.Fragment>
        );
    }

    templateCustomers(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }

    templateTechnician(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }

    /*templateTechnn(item) {
        return (
            <div className="country-item">
                <div id={item.label}>{item.label}</div>
            </div>
        );
    }*/

    justifyTemplate(option) {
        return <i className={option.icon}></i>;
    }
    templateTeam(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    templateWeek(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    dateTemplate(date) {
        let isExists = false;
        for (let i = 0; i < this.state.daysProcessed.length; i++) {
            if(this.state.daysProcessed[i].getFullYear() === date.year &&
                this.state.daysProcessed[i].getMonth() === date.month &&
                this.state.daysProcessed[i].getDate() === date.day){
                isExists = true;
            }
        }
        if (isExists) {
            return (
                <div style={{backgroundColor: '#1dcbb3', color: '#ffffff', borderRadius: '10%', padding: '1px 0px 2px 0px'}}>{date.day}</div>
            );
        }
        else {
            return date.day;
        }
    }



    searchWeeks(event) {
        setTimeout(() => {
            let filteredWeeks;
            if (!event.query.trim().length) {
                filteredWeeks = [...this.state.weeks];
            } else {
                filteredWeeks = this.state.weeks.filter((week) => {
                    return (week.label.toString()).toLowerCase().includes((event.query.toString()).toLowerCase());
                });
            }
            this.setState({ filteredWeeks });
        }, 250);
    }

    clearFilters = () => {
        this.setState({
            datesFilter: [],
            technicianForm: '',
            teamForm: '',
            numberWo: null,
            workOrder: null,
            serviceTypeForm: '',
            selectedRegister: this.registers[0],
            selectedBilled: this.billed[0],

        })
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded small-button" onClick={() => this.editTimeSheet(rowData)} />
            </React.Fragment>
        );
    }

    editTimeSheet(row) {
        this.setState({
            dataTableSelectValue: {...row},
            timesheetDialog: true
        });
        this.loadCatalogCustomers();
        this.showData(row);

    }

    showMap() {
        this.setState({
            mapsDialog: true
        });

    }

    actionBodyTemplateOrigin(rowData) {

        if(rowData.source === 'W') {
            return <span className='ta-center' style={{backgroundColor: "yellowgreen"}}>{'Web'}</span>;
        } else if (rowData.source === 'A') {
            return <span style={{backgroundColor: '#0daafd'}}>{'App'}</span>;
        } else {
            return <span></span>;
        }
    }
    actionBodyTemplateStatus_edit(rowData) {
        if(rowData.reviewed === true) {
        return <Button  className="p-button-success" icon='pi pi-check-circle' onClick={() => this.confirmationDialog(rowData)}/>;
        } else if (rowData.reviewed === false) {
            return <Button className="p-button-danger" icon='pi pi-times-circle'  onClick={() => this.confirmationDialog(rowData)}/>;
        } else if (rowData.reviewed === null){
            return <Button className="p-button-warning" icon='fa fa-pencil-square-o'   onClick={() => this.confirmationDialog(rowData)}/>;
        } else {
            return <span></span>;
        }

    }

    reviewedTimesheet = (modal) => () => {
        this.setState({chargedYet: false});
        if (modal === 'Reviewed') {
            const review = true;
            const params = {
                "timesheet_id": this.state.rowData.id,
                "reviewed": review,
            }
            this.worxflowServices.reviewedTimesheets(params).then(resp => {
                this.setState({chargedYet: false});
                if (resp.status === 200) {
                    this.setState({displayConfirm: false})
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Timesheet Updated', life: 3000 });
                    this.searchReport(new Date());
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                this.setState({displayConfirm: false})
                console.error("Error en el servicio: ", error);
            });
        } else {
            const review = false;
            const params = {
                "timesheet_id": this.state.rowData.id,
                "reviewed": review,}
            this.worxflowServices.reviewedTimesheets(params).then(resp => {
                this.setState({chargedYet: false});
                if (resp.status === 200) {
                    this.setState({displayConfirm: false})
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Timesheet Updated', life: 3000 });
                    this.searchReport(new Date());
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                this.setState({displayConfirm: false})
                console.error("Error en el servicio: ", error);
            });
        };
    }

    confirmationDialog(rowData) {
        if (this.state.dataTableSelectValue) {

        }
        this.setState({displayConfirm: true,
            rowData});
    }


    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false
        });
    }

    updateDataTable(){
        const customerr = (this.state.report.customer.label !== undefined) ? this.state.report.customer.label : this.state.report.customer
        const datee = this.state.report.date
        const usernamee = this.state.selectedTechnician.email
        const first_namee = this.state.selectedTechnician.first_name
        const last_namee = this.state.selectedTechnician.last_name
        const work_endedd = this.state.workEnded1 + ":"  + this.state.workEnded2 + ":00"
        const work_orderr = this.state.report.work_order
        const work_startedd = this.state.workStarted1 + ":" + this.state.workStarted2 + ":00"

        this.setState({
            dataTableSelectValue: {
                ...this.state.dataTableSelectValue,
                email: usernamee,
                last_name: last_namee,
                first_name: first_namee,
                work_order: work_orderr,
                customer: customerr,
                date: datee,
                work_started: work_startedd,
                work_ended: work_endedd
            }
        })

        }

    updateTimeSheet(){
        this.setState({chargedYet: false})
        let rawReport = [...this.state.rawReport];
        let dataTableSelectValue = {...this.state.dataTableSelectValue};
        this.worxflowServices.updateTimesheetDif(dataTableSelectValue).then(resp => {
            if (resp.status ===  "success") {
                let report = rawReport.map(p => p.id === dataTableSelectValue.id ? dataTableSelectValue : p);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Timesheet Updated', life: 3000 });
                this.setState({
                    timesheetDialog: false,
                    rawReport: report,
                    dataTableSelectValue: this.emptyTimesheet,
                    chargedYet: true
                });
            }
        }).catch(error => {
            this.setState({chargedYet: true});
            this.toast.show({severity: 'error', summary: 'Error', detail: error.response?.data?.message});
            console.error("Error en el servicio: ", error);
        });
    }

    closeConfirmModal(){
        this.setState({
            report: {
                ...this.state.report,
                work_order: "",
                customer: "",
                customer_id: undefined,
                reason_id: "",
                date: "",
                technicianForm: ""
            },
            displayConfirm: false,
            updateTimeSheet: false,
            dataTableSelectValue: null,
            technicianForm: null,
            deleteSheetDialog: false,
            processingAction: false,
            selectedHour: null,
            selectedHour2: null,
            timeHour: null,
            timeHour2: null,
            timeMinutes: null,
            timeMinutes2: null,
            selectedMinutes: null,
            selectedMinutes2: null
        });
    }

    callTimesheet(currentLink) {
        currentLink = currentLink === undefined ? "" : currentLink;
        this.worxflowServices.getTimesheetMobile(null, currentLink).then(resp => {
            let totalPage = resp.total / resp.page_size;
            totalPage = Math.trunc(totalPage);
            if(resp.total % resp.page_size > 0){
                totalPage += 1;
            }
            resp.results.forEach(element => {
                element.total_hours_f = rounded(element.total_hours_f)
                const reason = this.state.reasonsNoWO.find(e => e.id === element.reason_id);
                element.reason_desc = reason !== undefined ? reason.name : "";
            })
            this.setState({
                reports: resp.results,
                chargedYet: true,
                nextLink: resp.links.next,
                previousLink: resp.links.previous,
                totalRow: resp.total,
                pageCurrent: resp.page,
                totalPage,
                currentLink
            });
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }

    hideDialog() {
        this.setState({
            report: {
                ...this.state.report,
                work_order: ""
            },
            timesheetDialog: false,
            mapsDialog: false,
            auditTimesheetDialog: false,
            selectedTechnician: ""
        });
    }


    hideMap() {
        this.setState({
            mapsDialog: false
        });

    }

    changeHandlerTimesheet = (e) => {
        let dataTableSelectValue = {...this.state.dataTableSelectValue};
        if (e.target.name === 'qty_hours_fe_f' && !isAmount(e.target.value)) return
        if (e.target.name === 'qty_hours_fe_f') {
            dataTableSelectValue['difference_timesheet_f'] = Number(e.target.value) - Math.abs(Number(dataTableSelectValue['total_hours_f']));
        }

        dataTableSelectValue[`${e.target.name}`] = e.target.value;
        this.setState({dataTableSelectValue});

    };
    params;


    auditTimesheets() {

        let dataTableSelectValue = {...this.state.dataTableSelectValue};
        const id = dataTableSelectValue.id;
        if (dataTableSelectValue.id !== 0) {
            this.worxflowServices.auditTimesheet(id).then(resp => {
        if (resp.status === "success") {
            const respAudit = resp.data;
            this.setState({respAudit,
                auditTimesheetDialog: true,});
        }
    }).catch(error => {
    this.toast.show({severity: 'error', summary: 'Error', detail: error.response?.data?.message});
    console.error("Error en el servicio: ", error);
    });
        } else {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Select only one row.'
            });
        }
    }

    payTimesheets() {
        let dataTableSelectValue = {...this.state.dataTableSelectValue};
        let dataTableId = dataTableSelectValue.id
        let  dataTablePay = dataTableSelectValue.is_payment
        let is_payment = {}
        this.setState({is_payment})
        console.log(dataTableId)
        console.log(dataTablePay)
        if (dataTablePay === false) {
            const params = {
                timesheet_id: dataTableId,
                is_payment: true
            }
            this.worxflowServices.payTimesheet(params).then(resp => {
                this.setState({chargedYet: false});
                if (resp.status === 200) {
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Timesheet Updated', life: 3000 });
                    this.searchReport(new Date());
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                console.error("Error en el servicio: ", error);
            });
        } else {
            const params = {
                timesheet_id: dataTableId,
                is_payment: false
            }
            this.worxflowServices.payTimesheet(params).then(resp => {
                this.setState({chargedYet: false});
                if (resp.status === 200) {
                    this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Timesheet Payment', life: 3000 });
                    this.searchReport(new Date());
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                console.error("Error en el servicio: ", error);
            });
        }
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    formatDataListBefore = (data) => {
        const keysToExclude = ["source", "work_st", "work_ot", "work_pto", "work_diff", "is_legacy", "status", "id", "profile", "control_id", "customer_id"];
        const beforeObject = (data.before && typeof data.before === 'string') ? JSON.parse(data.before) : {};
        const afterObject = (data.after && typeof data.after === 'string') ? JSON.parse(data.after) : {};
        return Object.entries(beforeObject).filter(([key]) => !keysToExclude.includes(key)).map(([key, value], i) => {
            return <p key={i} style={{color: afterObject[key] !== value ? 'red' : 'black'}}>{`${key}: ${value}`}</p>
        });
    }

    formatDataListAfter = (data) => {
        const keysToExclude = ["source", "work_st", "work_ot", "work_pto", "work_diff", "is_legacy", "status", "id", "profile", "control_id", "customer_id"];
        const beforeObject = (data.before && typeof data.before === 'string') ? JSON.parse(data.before) : {};
        const afterObject = (data.after && typeof data.after === 'string') ? JSON.parse(data.after) : {};
        return Object.entries(afterObject).filter(([key]) => !keysToExclude.includes(key)).map(([key, value], i) => {
            return <p key={i} style={{color: beforeObject[key] !== value ? 'red' : 'black'}}>{`${key}: ${value}`}</p>
        });
    }

    onChangeSwitch(e) {
        this.setState({isSwitched: e.value});
        this.searchReportList()
    }


    searchOptions = (e) => {
        if (e && e.query) {
            if (e.query.length === 4) {
                const params = {
                    wo_number: e.query
                }
                this.worxflowServices.postListWoNumber(params).then(resp => {
                    const work_order_options = resp.data
                    this.setState({
                        work_number: work_order_options,
                        chargedYet: true
                    });
                }).catch(error => {
                    console.error("Error en el servicio: ", error);
                });
            }
            else if (e.query.length > 4) {
                let work_number = this.state.work_number || [];
                let filteredOptions = work_number.filter(option => option.name.includes(e.query));
                this.setState({
                    work_number: filteredOptions,
                });
            }
        }
    }

    searchWoO(event) {
        setTimeout(() => {
            let filteredWoO;
            if (!event.query.trim().length) {
                filteredWoO = [...this.state.workOrder];
            } else {
                filteredWoO = this.state.workOrder.filter((workOrder) => {
                    return workOrder.name.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredWoO });
        }, 250);
    }

    templateOptions(option) {
        return (
            <div className="country-item">
                <div >{option.name}</div>
            </div>
        );
    }

    templateWorkOrder(item) {
        return (
            <div className="country-item">
                <div id={item.name}>{item.name}</div>
            </div>
        );
    }





    render() {
        const renderFooterConfirm = (
            <div>
                <Button icon="fa fa-check-square-o" onClick={this.reviewedTimesheet('Reviewed')} label="Reviewed"
                        className="p-button-success"/>
                <Button icon="fa fa-minus-square-o" onClick={this.reviewedTimesheet('Unreviewed')} label="Unreviewed"/>
            </div>
        );
        const auditTimesheetsDialog = (
            <Dialog visible={this.state.auditTimesheetDialog} style={{width: '11    00px'}} header="Audit Technician"
                    className="p-fluid"  onHide={this.hideDialog}>
                <div className="grid datatable-crud-demo">
                    <div className="card">
                    <DataTable style={{width: '1000px'}}
                              value={this.state.respAudit} responsiveLayout="scroll" showGridlines header="Audit" dataKey="id" paginator rows={10}  rowsPerPageOptions={[5, 10, 25]}>
                        <Column field="timesheet_id" header="timesheet id"></Column>
                        <Column field="user" header="User"></Column>
                        <Column body={this.formatDataListBefore} header="Before" ></Column>
                        <Column body={this.formatDataListAfter} header="After" ></Column>
                        <Column field="date" header="Date"></Column>
                    </DataTable >
                </div>
            </div>
            </Dialog>
        );

        const maskIsPayment = (rowData) => {
            let payment = rowData.is_payment

            if (payment === true) {
                return (
                    <div className="ta-center-block">
                        <i className="pi pi-check-circle" style={{fontSize: '20px', color: 'limegreen'}}></i>
                    </div>
                );
            } else {

                return (
                    <div className="ta-center-block">
                        <i className="pi pi-times-circle" style={{fontSize: '20px', color: 'crimson'}}></i>
                    </div>



                );
            }
        }
        const maskIsPaymentStatus = (rowData) => {
            let status = rowData.status
            let checked = false
            if (status === "1") {
                /*status active*/
                return (
                    <div className="ta-center-block">
                        <Checkbox checked={checked}></Checkbox>
                    </div>
                );
            } else {
                /*status processed*/
                let checked = true
                return (
                    <div className="ta-center-block">
                        <Checkbox checked={checked}></Checkbox>
                    </div>
                );
            }
        }
        const timesheetDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" disabled={!this.state.chargedYet} className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" loading={!this.state.chargedYet} onClick={() => this.updateTimeSheet('displayConfirm')}
                        disabled={this.state.processingAction} onHide={this.hideDialog}/>
            </React.Fragment>
        );

        const mapDialogFooter = (
            <React.Fragment>
                <Button label="Close Map" icon="pi pi-times" disabled={!this.state.chargedYet} className="p-button-text" onClick={this.hideMap} />

            </React.Fragment>
        );

        const mapsDialog = (
            <div>
                <Dialog visible={this.state.mapsDialog} style={{width: '750px'}} header="Maps" modal
                        className="p-fluid" footer={mapDialogFooter} onHide={this.hideMap}>
                    <Maps dataSet={this.state} />
                </Dialog>
            </div>
        );

        const timesheetDialog = (
            <div>
                <Dialog visible={this.state.timesheetDialog} style={{width: '750px'}} header="Details" modal
                        className="p-fluid" footer={timesheetDialogFooter} onHide={this.hideDialog}>
                    <div className="grid p-fluid">
                        <div className="grid p-fluid" style={{width: '900px', paddingLeft: '15px'}}>
                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                <b>Technician: </b>
                                <AutoComplete
                                    className="form-element"
                                    placeholder="Select a technician"
                                    value={this.state.report.technician}
                                    field="label" dropdown
                                    disabled={this.state.report.technician !== undefined}
                                    /*itemTemplate={this.templateTechnn}
                                    suggestions={this.state.filteredTechnn}
                                    name="selectedTechnician"
                                    onChange={this.changeHandlerReport}
                                    completeMethod={this.searchTechnn}*/
                                />
                                {this.state.errors?.reason_id !== undefined ?
                                    <small className="offline">{this.state.errors?.reason_id}</small>
                                    : ""
                                }

                            </div>

                            <div className="col-12 xl:col-6 lg:col-6 sm:col-6" style={{width: '250px', height: '30px', paddingTop: '23px', paddingLeft: '70px'}}>
                                {this.state.dataTableSelectValue.source !== "W" && (
                                    <Button label="View Maps  - - - >" className="p-button-success" loading={!this.state.chargedYet} onClick={() => this.showMap()}/>
                                )}
                            </div>
                        </div>

                    </div>
                    <Card>
                        <div className="grid p-fluid">
                            <div className="grid p-fluid" style={{paddingTop: '5px'}}>
                                <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                    <b>WorkOrder: </b>
                                    <AutoComplete
                                        placeholder="Work order"
                                        name="work_order" dropdown
                                        itemTemplate={this.templateWorkOrder}
                                        suggestions={this.state.filteredWoO}
                                        completeMethod={this.searchWoO}
                                        onChange={this.changeHandlerWorkOrder}
                                        field="name"
                                        value={this.state.dataTableSelectValue.work_order}/>

                                    {this.state.errors?.workOrder !== undefined ?
                                        <small className="offline">{this.state.errors?.workOrder}</small>
                                        : ""
                                    }
                                </div>

                                <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                    <b>Customer: </b>
                                    <AutoComplete
                                        className="form-element"
                                        placeholder="Customer"
                                        name="customer"
                                        suggestions={this.state.filteredCustomer}
                                        value={this.state.report.customer}
                                        field="label" dropdown
                                        itemTemplate={this.templateCustomers}
                                        completeMethod={this.searchCustomers}
                                        onChange={this.changeHandlerReport}/>
                                    {this.state.errors?.customer !== undefined ?
                                        <small className="offline">{this.state.errors?.customer}</small>
                                        : ""
                                    }
                                </div>


                                <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                    <b>Date: </b>
                                    <InputText id="calendar" value={this.state.dataTableSelectValue.date} name="date"
                                              onChange={this.changeHandlerReport}
                                              disabled={this.state.dataTableSelectValue.date !== undefined}
                                              className={this.state.errors?.date !== undefined ? "form-element p-invalid" : "form-element"}/>
                                    {this.state.errors?.date !== undefined ?
                                        <small className="offline">{this.state.errors?.date}</small>
                                        : ""
                                    }
                                </div>
                                <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                    <b>After hours: </b>
                                    <SelectButton value={this.state.report.after_hours}
                                                  name="after_hours"
                                                  options={this.options}
                                                  optionLabel="name"
                                                  onChange={this.changeHandlerReport}
                                                  className="button-blue"
                                    />
                                </div>
                                <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                    <b>Per diem: </b>
                                    <InputNumber value={this.state.report.per_diem}
                                                 name="per_diem"
                                                 onValueChange={(e) => this.setState({
                                                     report: {
                                                         ...this.state.report,
                                                         per_diem: e.value
                                                     },
                                                     dataTableSelectValue: {
                                                         ...this.state.dataTableSelectValue,
                                                         per_diem: e.value
                                                     }
                                                 })}
                                    />
                                </div>
                                <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                                    <b>PTO: </b>
                                    <InputNumber value={this.state.report.pto}
                                                 name="pto"
                                                 onValueChange={(e) => this.setState({
                                                     report: {
                                                         ...this.state.report,
                                                         pto: e.value
                                                     },
                                                     dataTableSelectValue: {
                                                         ...this.state.dataTableSelectValue,
                                                         pto: e.value
                                                     }
                                                 })}
                                    />
                                </div>
                                <div className="col-12 md:col-2" style={{width: '110px'}}>
                                    <b>Work started: </b>
                                    <Dropdown
                                        className={this.state.errors?.work_started !== undefined ? "form-element p-invalid" : "form-element"}
                                        value={this.state.workStarted1}
                                        placeholder="Hour"
                                        name="work_started"
                                        onChange={this.onHourChange}
                                        options={this.hours}
                                        optionLabel="hr1" editable
                                        input type="number"/>
                                    {this.state.errors?.work_started !== undefined ?
                                        <small className="offline">{this.state.errors?.work_started}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-field p-grid mt-12" style={{paddingTop: '15px', fontSize: '20px'}}>
                                    <b>:</b>
                                </div>
                                <div className="col-12 md:col-2" style={{paddingTop: '24px'}}>
                                    <Dropdown
                                        className={this.state.errors?.work_started !== undefined ? "form-element p-invalid" : "form-element"}
                                        value={this.state.workStarted2}
                                        placeholder="Minutes"
                                        name="work_started"
                                        onChange={this.onMinutesChange}
                                        options={this.minutes}
                                        optionLabel="min" editable/>
                                </div>
                                {/*<div className="col-12 md:col-2" style={{paddingTop: '24px', width: '95px'}}>
                        <Dropdown
                            className={this.state.errors?.work_started !== undefined ? "form-element p-invalid" : "form-element"}
                            value={this.state.selectedSchedule}
                            placeholder="AM"
                            name="work_started"
                            onChange={this.onScheduleStartChange}
                            options={this.schedule}
                            optionLabel="sch"/>

                    </div>*/}

                                <div className="col-12 md:col-4" style={{paddingLeft: '140px', width: '250px'}}>
                                    <b>Work ended: </b>
                                    <Dropdown
                                        className={this.state.errors?.work_ended !== undefined ? "form-element p-invalid" : "form-element"}
                                        value={this.state.workEnded1}
                                        placeholder="Hour"
                                        name="work_ended"
                                        onChange={this.onHourChange2}
                                        options={this.hours}
                                        optionLabel="hr2" editable/>
                                    {this.state.errors?.work_ended !== undefined ?
                                        <small className="offline">{this.state.errors?.work_ended}</small>
                                        : ""
                                    }
                                </div>
                                <div className="p-field p-grid mt-12" style={{paddingTop: '15px', fontSize: '20px'}}>
                                    <b>:</b>
                                </div>
                                <div className="col-12 md:col-2" style={{paddingTop: '24px'}}>
                                    <Dropdown
                                        className={this.state.errors?.work_ended !== undefined ? "form-element p-invalid" : "form-element"}
                                        value={this.state.workEnded2}
                                        placeholder="Minutes"
                                        name="work_ended"
                                        onChange={this.onMinutesChange2}
                                        options={this.minutes}
                                        optionLabel="min" editable/>
                                </div>
                                {/*<div className="col-12 md:col-2" style={{paddingTop: '24px', width: '95px'}}>
                        <Dropdown
                            className={this.state.errors?.work_ended !== undefined ? "form-element p-invalid" : "form-element"}
                            value={this.state.selectedSchedule2}
                            placeholder="AM"
                            name="work_ended"
                            onChange={this.onScheduleStartChange2}
                            options={this.schedule}
                            optionLabel="sch"/>
                    </div>*/}
                            </div>
                        </div>
                    </Card>

                        <div className="p-field" style={{paddingTop: '15px'}}>
                            <label htmlFor="qty_hours_fe_f">Qty Hours FE / Corrigo</label>
                            <InputText
                                name="qty_hours_fe_f"
                                value={this.state.dataTableSelectValue ? this.state.dataTableSelectValue.qty_hours_fe_f : ''}
                                onChange={this.changeHandlerTimesheet}
                                required autoFocus
                            />
                        </div>
                        <div className="p-field" style={{paddingTop: '10px'}}>
                            <label htmlFor="notes">Notes</label>
                            <InputTextarea
                                name="notes"
                                value={this.state.dataTableSelectValue ? this.state.dataTableSelectValue.notes : ''}
                                onChange={this.changeHandlerTimesheet}
                                required
                                rows={3}
                                cols={20}
                                maxLength={300}
                            />
                        </div>


                </Dialog>
            </div>
    );
    const confirmDialog = (
        <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '15vw'}}
                    footer={renderFooterConfirm}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        );


        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el}/>
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}}
                                             animationDuration=".7s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12" style={{paddingBottom: '0px', paddingTop: '0px'}}>
                            <div>
                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-4">
                                        <Calendar id="range" value={this.state.datesFilter} name="datesFilter"
                                                  showIcon={true} showWeek={true} placeholder="Reporting Period: *"
                                                  onChange={this.changeHandler} selectionMode="range" readOnlyInput
                                                  className={this.state.errors?.datesFilter !== undefined ? "form-element p-invalid" : "form-element "}/>
                                        {this.state.errors?.datesFilter !== undefined ?
                                            <small className="offline">{this.state.errors?.datesFilter}</small>
                                            : ""
                                        }
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Dropdown className="form-element"
                                                  placeholder="Service type"
                                                  name="serviceTypeForm"
                                                  optionLabel="label"
                                                  options={this.serviceTypes}
                                                  value={this.state.serviceTypeForm}
                                                  onChange={this.changeHandler}
                                                  disabled={this.state.isSwitched}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <AutoComplete
                                            className="form-element"
                                            placeholder="Select a technician"
                                            name="technicianForm"
                                            suggestions={this.state.filteredTechnicians}
                                            value={this.state.technicianForm}
                                            field="label" dropdown
                                            itemTemplate={this.templateTechnician}
                                            completeMethod={this.searchTechnicians}
                                            onChange={this.changeHandler}/>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <AutoComplete
                                            className="form-element"
                                            placeholder="Select a Team"
                                            name="teamForm"
                                            suggestions={this.state.filteredTeams}
                                            value={this.state.teamForm}
                                            field="label" dropdown
                                            itemTemplate={this.templateTeam}
                                            completeMethod={this.searchTeams}
                                            onChange={this.changeHandler}
                                            disabled={this.state.isSwitched}
                                        />

                                        <div className="grid" style={{paddingTop: '25px'}}>

                                            <div className="col-12 md:col-4" style={{paddingTop: '0px'}}>
                                                {this.state.dataTableSelectValue.id !== 0 && (
                                                    <Button label="Billed" className="p-button-success"
                                                            onClick={this.payTimesheets}/>
                                                )}
                                            </div>

                                        </div>


                                    </div>

                                    <div className="col-12 md:col-4" style={{display: 'flex'}}>
                                        <div style={{flex: '1'}}>
                                            <AutoComplete
                                                className="form-element"
                                                placeholder="WO #"
                                                name="numberWo"
                                                suggestions={this.state.work_number}
                                                value={this.state.numberWo}
                                                field="name" dropdown
                                                itemTemplate={(option) => this.templateOptions(option)}
                                                completeMethod={(e) => this.searchOptions(e)}
                                                onChange={this.changeHandler}
                                                disabled={this.state.isSwitched}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 md:col-2">
                                        <Dropdown className="form-element"
                                                  name="selectedBilled"
                                                  optionLabel="bl"
                                                  options={this.billed}
                                                  value={this.state.selectedBilled}
                                                  onChange={this.onBilledChange}
                                                  disabled={this.state.isSwitched}
                                        />
                                        <small className="ta-right" style={{display: "block", color: "white"}}>.</small>
                                        <div className="grid">
                                            <div className="col-9" style={{width: 220, paddingLeft: '130px'}}>
                                                <Button icon="pi pi-filter-slash" onClick={this.clearFilters}
                                                        tooltip="Clear filters" tooltipOptions={{position: 'top'}}
                                                        style={{width: 50, paddingLeft: '4px'}}/>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="col-12 md:col-2">
                                        <Dropdown className="form-element"
                                                  name="selectedRegister"
                                                  optionLabel="reg"
                                                  options={this.registers}
                                                  value={this.state.selectedRegister}
                                                  onChange={this.onRegisterChange}
                                        />
                                        <small className="ta-right" style={{display: "block", color: "white"}}>.</small>
                                        <div className="grid">
                                            <div className="col-10 md:col-8">
                                                <Button label="Search" onClick={this.searchReport}/>
                                            </div>
                                            <div className="col-10 md:col-4">
                                                <InputSwitch checked={this.state.isSwitched}
                                                             onChange={this.onChangeSwitch}
                                                             tooltip="Show Second Table"
                                                             tooltipOptions={{
                                                                 className: 'blue-tooltip',
                                                                 position: 'bottom'
                                                             }}/>
                                            </div>
                                        </div>


                                    </div>


                                </div>


                            </div>

                        </div>


                        <div className="col-12" style={{paddingTop: '0px'}}>
                            {this.state.isSwitched ?
                                <>
                                    <Button icon="pi pi-upload" className="p-button-help" label="Export"
                                            onClick={() => this.csvExporter1.exportCSV()} style={{margin: 1}}/>
                                    <DataTable ref={(el) => {
                                        this.dt = el;
                                        this.csvExporter1 = el;
                                    }} value={this.state.rawReport2}
                                               rowGroupMode="subheader"
                                               groupRowsBy="header"
                                               selection={this.state.dataTableSelectValue}
                                               onSelectionChange={(e) =>
                                                   this.setState({dataTableSelectValue: e.value})}
                                               sortField="header" groupField="header" dataKey="id"
                                               rowGroupHeaderTemplate={this.headerTemplate}
                                               rowGroupFooterTemplate={this.footerTemplate}
                                               showGridlines selectionMode="single"
                                               scrollable scrollHeight="550px" scrollDirection="both"
                                               paginator={true} rows={250}
                                               tableClassName="slim-table">
                                        <Column body={maskIsPaymentStatus} header="Is Processed"
                                                className="ta-center-block"
                                                style={{fontSize: 12, minWidth: '4rem', width: 71, paddingLeft: '4px'}}/>
                                    <Column body={this.actionBodyTemplate} exportable={false} header="Edit"
                                            style={{fontSize: 12,width: 36, paddingLeft: '4px'}}></Column>
                                    <Column body={this.actionBodyTemplateOrigin} header="Data Source From"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 52, paddingLeft: '4px'}}/>
                                    <Column body={this.actionBodyTemplateStatus_edit} header="Is Reviewed?"
                                            className="ta-center-block"
                                            style={{fontSize: 12, minWidth: '4rem', width: 71, paddingLeft: '4px'}}></Column>
                                    <Column body={maskIsPayment} header=" Is Billed?"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 50, paddingLeft: '4px'}}/>
                                    <Column field="email" sortable header="Technician Name"
                                            style={{fontSize: 12, width: 180}}/>
                                    <Column field="work_order" sortable header="Work Order #"
                                            style={{fontSize: 12, width: 180}}/>
                                    <Column field="customer" sortable header="Customer Name"
                                            style={{fontSize: 12, width: 190}}/>
                                    <Column field="after_hours" sortable header="After Hours"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 65, paddingLeft: '4px'}}/>
                                    <Column field="work_started" sortable header="Started" className="ta-center-block"
                                            style={{fontSize: 12, width: 120}}/>
                                    <Column field="work_ended" sortable header="Ended" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="total_hours_f" sortable header="Total Hours"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="st_hours_f" sortable header="ST Hours" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="ot_hours_f" sortable header="OT Hours" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="per_diem" sortable header="Per Diem" className="ta-center-block"
                                            style={{fontSize: 12, width: 80}}/>
                                    <Column field="pto" sortable header="PTO" className="ta-center-block"
                                            style={{fontSize: 12, width: 80}}/>
                                    <Column field="work_pto" sortable header="Requested PTO" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="date" sortable header="Date of service Call"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 110}}/>
                                    <Column field="difference_timesheet_f" sortable
                                            header="Difference TimeSheets/Platforms"
                                            className="ta-center-block" style={{fontSize: 12, width: 200}}/>
                                    <Column field="d_created" sortable header="Entry Created on"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 160}}/>
                                    <Column field="qty_hours_fe_f" sortable header="Qty Hours in FE/Corrigo"
                                            className="ta-center-block" style={{fontSize: 12, width: 100}}/>
                                    <Column field="type" sortable header="Service Type" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="notes" sortable header="Notes WO FE & Corrigo"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 300}}/>
                                    <Column field="address" sortable header="Address" className="ta-center-block"
                                            style={{fontSize: 12, width: 400}}/>
                                </DataTable>
                                </>
                                :
                                <>
                                <Button icon="pi pi-upload" className="p-button-help" label="Export" onClick={() => this.csvExporter2.exportCSV()} style={{margin: 1}}/>
                                <DataTable ref={(el) => { this.csvExporter2 = el; }} value={this.state.rawReport}
                                           rowGroupMode="subheader"
                                           groupRowsBy="header"
                                           selection={this.state.dataTableSelectValue}
                                           onSelectionChange={(e) =>
                                               this.setState({dataTableSelectValue: e.value})}
                                           sortField="header" groupField="header" dataKey="id"
                                           showGridlines selectionMode="single"
                                           scrollable scrollHeight="550px" scrollDirection="both"
                                           paginator={true} rows={250}
                                           tableClassName="slim-table">
                                    <Column body={maskIsPaymentStatus} header="Is Processed"
                                            className="ta-center-block"
                                            style={{fontSize: 12, minWidth: '4rem', width: 71, paddingLeft: '4px'}}/>
                                    <Column body={this.actionBodyTemplate} exportable={false} header="Edit"
                                            style={{fontSize: 12,width: 36, paddingLeft: '4px'}}></Column>
                                    <Column body={this.actionBodyTemplateOrigin} header="Data Source From"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 52, paddingLeft: '4px'}}/>
                                    <Column body={this.actionBodyTemplateStatus_edit} header="Is Reviewed?"
                                            className="ta-center-block"
                                            style={{fontSize: 12, minWidth: '4rem', width: 71, paddingLeft: '4px'}}></Column>
                                    <Column body={maskIsPayment} header=" Is Billed?"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 50, paddingLeft: '4px'}}/>
                                    <Column field="email" sortable header="Technician Name"
                                            style={{fontSize: 12, width: 180}}/>
                                    <Column field="work_order" sortable header="Work Order #"
                                            style={{fontSize: 12, width: 180}}/>
                                    <Column field="customer" sortable header="Customer Name"
                                            style={{fontSize: 12, width: 190}}/>
                                    <Column field="after_hours" sortable header="After Hours"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 65, paddingLeft: '4px'}}/>
                                    <Column field="work_started" sortable header="Started" className="ta-center-block"
                                            style={{fontSize: 12, width: 120}}/>
                                    <Column field="work_ended" sortable header="Ended" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="total_hours_f" sortable header="Total Hours"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="st_hours_f" sortable header="ST Hours" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="ot_hours_f" sortable header="OT Hours" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="per_diem" sortable header="Per Diem" className="ta-center-block"
                                            style={{fontSize: 12, width: 80}}/>
                                    <Column field="pto" sortable header="PTO" className="ta-center-block"
                                            style={{fontSize: 12, width: 80}}/>
                                    <Column field="work_pto" sortable header="Requested PTO" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="date" sortable header="Date of service Call"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 110}}/>
                                    <Column field="difference_timesheet_f" sortable
                                            header="Difference TimeSheets/Platforms"
                                            className="ta-center-block" style={{fontSize: 12, width: 200}}/>
                                    <Column field="d_created" sortable header="Entry Created on"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 160}}/>
                                    <Column field="qty_hours_fe_f" sortable header="Qty Hours in FE/Corrigo"
                                            className="ta-center-block" style={{fontSize: 12, width: 100}}/>
                                    <Column field="type" sortable header="Service Type" className="ta-center-block"
                                            style={{fontSize: 12, width: 100}}/>
                                    <Column field="notes" sortable header="Notes WO FE & Corrigo"
                                            className="ta-center-block"
                                            style={{fontSize: 12, width: 300}}/>
                                    <Column field="address" sortable header="Address" className="ta-center-block"
                                            style={{fontSize: 12, width: 400}}/>
                                </DataTable>
                                </>

                            }


                        </div>
                    </div>
                </Card>
                {confirmDialog}
                {timesheetDialog}
                {mapsDialog}
                {auditTimesheetsDialog}
            </div>
        )
    }
}

export default Report;
