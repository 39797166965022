import axios from 'axios';

export class RutaServices {
    getConfig = (params) => {
        let access_token = sessionStorage.getItem('access_token');
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        };
    }

    getListTrackStore() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/trackstore/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    postFindGrafo(params) {
        return axios.post('https://ruta.snbxt.com/api/v/2/grafo/find/',
            params).then(resp => {
            return resp.data;
        });
    }


}
